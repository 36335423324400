
.gradientText {
    background-image: linear-gradient(to right, #8e44ad, #c0392b);
    color: transparent;
    -webkit-background-clip: text; /* For Safari and older versions of Chrome and Safari */
    background-clip: text;
}


.footerLink:before {
    content: "•";
    margin-right: 7px;
}


.footerLinkText {
    border-bottom: 1px solid rgba(250, 249, 248, .5);
}