@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    .gradientOutline::before {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        z-index: -1;
        background: linear-gradient(to right, #8e44ad, #c0392b);
        border-radius: inherit; /* if the element is rounded */
    }
}

.intercom-lightweight-app {
    z-index: 30 !important;
}

html, body {
    height: 100vh;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    /*background: black;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: gray;
}

:root {
    background: black;
}

.tl-br-hero {
    position: absolute;
    width: 100vw;
    left: 0;
    right: 0;
    opacity: 1;
    height: 575px;
}

.tl-br-hero::after {
    content: "";
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, .3) 50%, #000);
    height: 575px;
    left: 0;
    top: 0;
    width: 100%;
}

.tl-br-hero img {
    box-shadow: 0 0 800px 200px #000000;
    height: 575px;
    overflow: hidden;
    width: 100vw;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .tl-br-hero {
        position: absolute;
        width: 100%;
        height: 200px;
        left: 0;
        right: 0;
        opacity: 1;
    }

    .tl-br-hero::after {
        content: "";
        position: absolute;
        background-image: linear-gradient(rgba(0, 0, 0, .3) 30%, #000);
        left: 0;
        top: 0;
        height: 300px;
        width: 100%;
    }

    .tl-br-hero img {
        box-shadow: 0 0 800px 200px #000000;
        overflow: hidden;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

#background-video {
    width: 100vw;
    height: 575px;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/*.bg-tomorrowland, #root  {*/
/*  background-color: red;*/
/*}*/

.loading-container {
    width: 100vw;
    height: 100vh;
    background: black;
    left: 0;
    position: absolute;
    right: 0 !important;
    top: 0;
    opacity: 0.8;
}

.loading-container > img {
    width: 20%;
    position: absolute;
    top: 40%;
    left: 40%;
}

#megasac-button {
    display: none;
}

.faq-image {
    height: 300px;
    width: 100%;
    object-fit: cover;
}


.overflowText {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.wa-chat-box-poweredby {
    display: none;
}

.modal-header-fixed {
    width: 100%;
    top: 0;
    padding: 4px 0 0 10px;
    background-color: rgba(var(--c-neutral-800), var(--tw-bg-opacity));
    position: sticky;
    z-index: 999;
}

.bg-header-img {
    display: inline-flex;
    position: relative;
    z-index: 1;
}

.bg-header-img > img {
    height: 30%;
    overflow: hidden;
}


.bg-header-img::after {
    background: linear-gradient(180deg, transparent 1%, rgba(0, 0, 0, .9));
    content: "";
    display: inline-block;
    height: 100%;
    overflow: hidden;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.header {
    height: 50px; /* Set the height of the header */
    background-color: white; /* Set the background color to white */
    width: 100%; /* Full width of the viewport */
    top: 0; /* Position it at the top */
    z-index: 1000; /* Set a higher z-index to ensure it appears above other elements */
}

.content {

    position: relative;
    margin-top: 50px; /* Adjust margin-top of content to make space for the fixed header */
    /* Additional styles for your content */
}

.container-header {
    background-image: linear-gradient(237deg, #4a3c45, #1f1c1f);
    box-shadow: 0 2px 14px rgba(0, 0, 0, .27);
}

.divider {
    position: relative;
}

.divider::before {
    background: linear-gradient(90deg, transparent 0, #fff 50%, transparent);
    content: "";
    height: 1px;
    left: 50%;
    opacity: .15;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 80%;
}

.gradient-text {
    background-image: linear-gradient(to right, #8e44ad, #c0392b);
    color: transparent;
    -webkit-background-clip: text; /* For Safari and older versions of Chrome and Safari */
    background-clip: text;
}

.intercom-launcher {
    z-index: 10 !important;
}

.no-print {
    @media print {
        display: none;
    }
}