.scrolling-touch {
    -webkit-overflow-scrolling: touch;
}

.scroll-smooth {
    scroll-behavior: smooth;
}

.gradient-effect-start,
.gradient-effect-end {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px; /* Adjust width as needed */
    pointer-events: none;
}

.gradient-effect-start {
    left: 0;
    background: linear-gradient(to right, black, transparent);
}

.gradient-effect-end {
    right: 0;
    background: linear-gradient(to left, black, transparent);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrolling-touch::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrolling-touch {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
