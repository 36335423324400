// POPPINS
@font-face {
  font-family: Europa;
  font-weight: 300;
  src: url("../fonts/europa-regular.ttf");
}

@font-face {
  font-family: Europa;
  font-weight: 400;
  src: url("../fonts/europa-regular.ttf");
}

@font-face {
  font-family: Europa;
  font-weight: 500;
  src: url("../fonts/europa-bold.ttf");
}

@font-face {
  font-family: Europa;
  font-weight: 600;
  src: url("../fonts/europa-bold.ttf");
}

@font-face {
  font-family: Europa;
  font-weight: 700;
  src: url("../fonts/europa-bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Europa;
  --font-body: Europa;
}