.accommodationContainer {
    display: block;
    width: 100%;
    min-width: 320px;
    max-width: 480px;
}

.accommodationContainer:hover .accommodationImage {
    transform: scale(1.1); /* Adjust scale value as needed */
    transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}

.accommodationImage {
    width: 100%; /* Ensure the image fills the container */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}