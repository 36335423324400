
.heroImageContainer {
    position: absolute;
    width: 100vw;
    left: 0;
    right: 0;
    height: 575px;
}

.heroImageContainer::after {
    content: "";
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, .3) 40%, #000);
    height: 575px;
    left: 0;
    top: 0;
    width: 100%;
}

.heroImageContainer img {
    height: 570px;
    overflow: hidden;
    width: 100vw;
    object-fit: cover;
}

.card {

    cursor: pointer;
}

.card:hover {
    outline: 1px solid gray;
}

.container {
    margin-bottom: 38px;
    align-self: center;
    position: relative;
    width: 700px;
    height: 400px;
    overflow: hidden;
    cursor: pointer;
}


.imageContainer {
    width: 100%;
    height: 100%;
    background: url('https://pbs.twimg.com/media/F8Q_iwfWgAABsaw?format=jpg&name=4096x4096') center/cover;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.imageContainer:hover {
    transform: scale(1.2);
}

.text {
    position: absolute;
    bottom: 140px;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 10px;
    z-index: 20;
    cursor: pointer;
}

.playIcon {
    font-size: 62px;
    bottom: 1px;
}

.darkLayer {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha (last) value for transparency */
}
